/*******************************
         Site Overrides
*******************************/

/* https://stackoverflow.com/a/48653479 */

.ui.grid > .row > .evenmonth.column,
.ui.grid > .row > .oddmonth.column {
  margin-top: -(@rowSpacing / 2);
  margin-bottom: -(@rowSpacing / 2);
  padding-top: (@rowSpacing / 2);
  padding-bottom: (@rowSpacing / 2);
}
/* Odd month */
.ui.grid > .oddmonth.row,
.ui.grid > .oddmonth.column,
.ui.grid > .row > .oddmonth.column {
  background-color: @oddmonth !important;
  color: @black;
}
/* Even month */
.ui.grid > .evenmonth.row,
.ui.grid > .evenmonth.column,
.ui.grid > .row > .evenmonth.column {
  background-color: @evenmonth !important;
  color: @black;
}
/* Close to today */
.ui.grid > .todaycolor.row,
.ui.grid > .todaycolor.column,
.ui.grid > .row > .todaycolor.column {
  background-color: @todaycolor !important;
  color: @black;
}
